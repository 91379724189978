/* You can add global styles to this file, and also import other style files */
@use '@classi/spen' as spen;

* {
  box-sizing: border-box;
}

// 以下は全て <noscript> 用の style
.no-script-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
  height: 100%;
  max-width: 850px;

  .no-script-header {
    padding: 0 spen.$spacing-lv4;
    margin-top: spen.$spacing-lv8;

    @media (min-width: spen.$breakpoints-sm) {
      padding: 0 68px;
    }
    .logo {
      aspect-ratio: 180 / 58;

      width: 80px;
      @media (min-width: spen.$breakpoints-sm) {
        width: 180px;
      }
    }
  }

  .no-script-main {
    flex: 1 0 auto;
    padding: 0 spen.$spacing-lv4;

    @media (min-width: spen.$breakpoints-sm) {
      padding: 0 68px;
    }

    .page-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      border-bottom: 2px solid spen.$color-gray-lv4;
      padding-bottom: spen.$spacing-lv2;
      margin: spen.$spacing-lv4 0;
    }

    .page-content {
      display: block;
      padding: spen.$spacing-lv4;
      background: #fafafa;
      border: 1px solid spen.$color-gray-lv4;
      border-radius: spen.$spacing-lv1;

      .link-list {
        margin: spen.$spacing-lv3 0;
      }

      .link-title {
        font-weight: bold;
        margin: spen.$spacing-lv2 0;
      }

      a {
        text-decoration: none;
        color: #0088c7;
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
        -webkit-appearance: none;
        appearance: none;
      }
    }
  }
  .no-script-footer {
    .before-login-footer {
      background-color: spen.$color-gray-lv1;
      border-top: 1px solid spen.$color-gray-lv5;
      font-size: spen.$text-xs;

      margin-top: 60px;
      @media (min-width: spen.$breakpoints-sm) {
        margin-top: spen.$spacing-lv7;
        padding: spen.$spacing-lv6;
      }
    }

    .copyright {
      text-align: center;
      line-height: 1;

      border-top: 1px solid spen.$color-gray-lv5;
      padding: spen.$spacing-lv4 0;

      @media (min-width: spen.$breakpoints-sm) {
        border: none;
        padding: spen.$spacing-lv3 0 0;
      }
    }
  }
}
